import React, { useEffect } from 'react'
import LoginButton from '../components/LoginButton'
import LogoutButton from '../logout'
import { useAuth0 } from '@auth0/auth0-react'
import { redirect, useNavigate } from 'react-router-dom';
import SignupButton from '../components/SignupButton';
import mealPlannerScreenshot from '../assets/meal-planner-screenshot.png'
import plannersScreenshot from '../assets/planners-screenshot.png'
import { Carousel } from 'react-bootstrap';

function LandingPage() {
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();

    const goToHomePage = () => {
        navigate("home")
    }

    if (isAuthenticated) {
        goToHomePage();
    }

    return (
        <div id='landing-page'>
            <div id='landing-page-header'>
                <h1>Meal Planner App</h1>
                <div>
                    <LoginButton />
                    <SignupButton />
                </div>
            </div>
            <div id='landing-page-main'>
                <div id='landing-page-text'>
                    <h2>Create your own personalized meal plan</h2>
                    <ul>
                        <li>Create and save multiple meal planners to match your weekly needs</li>
                        <li>Easily add and remove recipes from your meal planner</li>
                    </ul>
                    <div className='flex-column'>
                        <p>Get started for free today!</p>
                        <SignupButton />
                    </div>
                    <p>Already have an account? <LoginButton /></p>
                </div>
                <Carousel id='carousel'>
                    <Carousel.Item>
                        <img src={plannersScreenshot}></img>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={mealPlannerScreenshot}></img>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    )
}

export default LandingPage