import React, { useState } from 'react'
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Calendar } from './Calendar';
import { useAuth0 } from '@auth0/auth0-react';
import backArrow from '../assets/arrow-return-left.svg'
import { Button } from 'react-bootstrap';
import RecipeList from './RecipeList';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Planner = ({ }) => {

    const { getAccessTokenSilently } = useAuth0();

    const { plannerId } = useParams();
    const [updated, setUpdated] = useState(true);
    const [plannerData, setPlannerData] = useState()
    const [selectedMealId, setSelectedMealId] = useState();

    const navigate = useNavigate();

    const addFood = async (recipeId, name, url, imageUrl) => {
        if (!selectedMealId) {
            return;
        }

        const token = await getAccessTokenSilently();

        const recipe = {
            id: recipeId,
            name: name,
            url: url,
            imageUrl: imageUrl
        }
        fetch(`${backendUrl}/meals/${selectedMealId}/recipe`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            mode: "cors",
            method: "POST",
            body: JSON.stringify(recipe)
        })
            .then(response => response.json())
            .then(data => setUpdated(true))
    }

    const removeFood = async (mealId, idToRemove) => {
        const token = await getAccessTokenSilently();

        fetch(`${backendUrl}/meals/${mealId}/recipe/${idToRemove}`, {
            mode: 'cors',
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(() => setUpdated(true));
    }

    useEffect(() => {
        if (!updated) {
            return;
        }

        const getPlanner = async () => {
            const token = await getAccessTokenSilently();
            fetch(`${backendUrl}/planner/${plannerId}`, {
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    setPlannerData(data);
                    setUpdated(false);
                })
                .catch(error => console.error(error));
        }

        getPlanner();
    }, [updated])
    return (
        <div className='main' id='planner'>
            <div className='sidebar' id='planner-sidebar'>
                <h1>ADD RECIPES</h1>
                {selectedMealId && <h2>{plannerData.meals.find(meal => meal.id === selectedMealId).mealDay} - {plannerData.meals.find(meal => meal.id === selectedMealId).mealType} </h2>}
                <RecipeList addFood={addFood} />
            </div>
            <div className='main-content'>
                <div id='planner-topbar'>
                    <Button onClick={() => navigate(-1)} variant='outline-secondary' id='planner-back-button'><img src={backArrow}></img></Button>
                    {plannerData && <h2>{plannerData.name}</h2>}
                    <div></div>
                </div>
                <div id='planner-grid'>
                    <div id='meal-labels'>
                        <p>BREAKFAST</p>
                        <p>LUNCH</p>
                        <p>DINNER</p>
                    </div>
                    <div id='day-labels'>
                        <p>MON</p>
                        <p>TUE</p>
                        <p>WED</p>
                        <p>THU</p>
                        <p>FRI</p>
                        <p>SAT</p>
                        <p>SUN</p>
                    </div>
                    {plannerData && <Calendar calendarData={plannerData.meals} removeFood={removeFood} addFood={addFood} setSelectedMealId={setSelectedMealId}></Calendar>}
                </div>
            </div>
        </div >
    );
}

export default Planner