import React from 'react'
import addButton from '../assets/add-button.svg'

const RecipeListItem = ({ id, name, url, addFood, imageUrl }) => {
    return (
        <div className='recipe-list-item'>
            <img src={imageUrl}></img>
            <p>{name}</p>
            <button onClick={() => addFood(id, name, url, imageUrl)}><img src={addButton}></img></button>
        </div>
    )
}

export default RecipeListItem