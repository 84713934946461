import React from 'react'
import x from '../assets/x.svg'

const Food = ({ id, food, removeFood, url }) => {
    return (
        <div className='flex-row food'>
            <a href={url} target='_blank'>{food}</a>
            <button onClick={() => removeFood(id)}><img src={x}></img></button>
        </div>
    )
}

export default Food