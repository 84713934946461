import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { Button } from 'react-bootstrap';

const SignupButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <Button variant='light' onClick={() => loginWithRedirect({
            authorizationParams: {
                screen_hint: 'signup'
            }
        })}>Sign Up</Button>
    )
}

export default SignupButton