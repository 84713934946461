import React, { useEffect, useState } from 'react'
import FoodListItem from './FoodListItem'
import { useAuth0 } from '@auth0/auth0-react';
import RecipeListItem from './RecipeListItem';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const RecipeList = ({ addFood }) => {
    const { getAccessTokenSilently } = useAuth0();

    const [foodList, setFoodList] = useState([]);

    useEffect(() => {
        const getRecipes = async () => {
            const token = await getAccessTokenSilently();

            fetch(`${backendUrl}/recipes`, {
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => response.json())
                .then(json => {
                    setFoodList(json);
                })
                .catch(error => console.error(error));
        }

        getRecipes();
    }, [])
    return (
        <div>
            <div id='recipe-list'>
                {foodList.map((foodItem) => <RecipeListItem key={foodItem.id} id={foodItem.id} name={foodItem.name} url={foodItem.url} imageUrl={foodItem.imageUrl} addFood={addFood} />)}
            </div>
        </div>
    )
}

export default RecipeList